import React, { useEffect, useState } from "react";
import Ribbon from "../../components/Ribbon";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const ForgotPassword = () => {
  const apiUrl = window.location.origin.includes("localhost")
    ? "http://localhost:5000"
    : "https://khamco-shop-backend.onrender.com";
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    document.title = "Forgot Password Form - Khamco Shop";
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await axios.post(`${apiUrl}/users/forgot-password`, {
        email: email,
      });
      toast.success(response.data.message);
      setLoading(false);
      navigate("/users/login");
    } catch (error) {
      setLoading(false);
      toast.error(error.response?.data?.message || error.message);
    }
  };
  return (
    <>
      <Ribbon />
      <main className="p-16">
        <section className="p-[4rem] grid justify-center items-center">
          <h1 className="text-center text-[2rem] font-minimoBold">Reset Password</h1>
          <form
            onSubmit={handleSubmit}
            className="flex flex-col justify-center items-center w-[90vw]"
          >
            <div className="w-full md:w-1/2 flex flex-col mx-auto my-4 relative">
              <label htmlFor="email" className="text-lg my-2 select-none self-start font-medium">Email</label>
              <input
              className="text-lg my-2 p-2 border border-gray-800 rounded-md outline-none font-sans focus:border-[blue]"
                type="email"
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter your email address"
              />
            </div>
            <div className="w-full md:w-1/2 flex flex-col mx-auto my-4 relative">
              <button type="submit" className="text-lg my-2 text-center bg-[blue] w-1/2 mx-auto p-4 text-white rounded-full border-none outline-none hover:bg-blue-900 cursor-pointer">
                {loading ? (
                  <i className="fas fa-spinner animate-spin"></i>
                ) : (
                  "Submit"
                )}
              </button>
            </div>
          </form>
        </section>
      </main>
    </>
  );
};

export default ForgotPassword;

import React, { useEffect, useState } from "react";
import Navbar from "../../components/Navbar";
import AdminPanel from "../../components/AdminPanel";
import { Link, useNavigate } from "react-router-dom";
import Footer from "../../components/Footer";
import axios from "axios";
import { toast } from "react-toastify";

const AdminDashboard = () => {
  const serverUrl = window.location.origin.includes("localhost")
    ? "http://localhost:5000/admin/dashboard"
    : "https://khamco-shop-backend.onrender.com/admin/dashboard";
  const [availableFabric, setAvailableFabric] = useState(0);
  const [averageServiceTime, setAverageServiceTime] = useState({});
  const [customers, setCustomers] = useState([{}]);
  const [invoiceData, setInvoiceData] = useState([]);
  const [totalRevenue, setTotalRevenue] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [display, setDisplay] = useState(false);
  const authKey = localStorage.getItem("jwt");
  const navigate = useNavigate();

  useEffect(() => {
    let isMounted = true;

    document.title = "Admin Dashboard - Khamco Shop";

    const fetchData = async () => {
      try {
        const response = await axios.get(serverUrl, {
          headers: { Authorization: `Bearer ${authKey}` },
        });
        if (isMounted) {
          setAvailableFabric(response.data.available_fabric);
          setAverageServiceTime(response.data.average_service_time);
          setCustomers(response.data.customers);
          setInvoiceData(response.data.invoice_data);
          setTotalRevenue(response.data.total_revenue);
          setDisplay(true);
          setIsLoading(false);
        }
      } catch (error) {
        if (isMounted) {
          setDisplay(false);
          setIsLoading(true);
          if (error.response) {
            if (
              error.response.status === 403 ||
              error.response.status === 400
            ) {
              toast.warn("You are not authorized to access that page");
              navigate("/");
            } else {
              toast.error(error.response.data?.message || error.message);
            }
          } else {
            // Handle network errors or errors without a response object
            toast.error("An error occurred: " + error.message);
          }
        }
      }
    };

    fetchData();

    return () => {
      isMounted = false;
    };
  }, [authKey, navigate, serverUrl]);

  return (
    <>
      <Navbar />
      <main className="p-[4rem]">
        <AdminPanel />
        <section className="admin-dashboard font-arial">
          <h1 className="text-center text-[2rem] font-minimoBold">
            Admin Dashboard
          </h1>
          {isLoading && (
            <div className="grid md:grid-cols-2 gap-[4rem] justify-center items-center w-[70vw] m-auto">
              <div className="shadow-[gray] p-8 rounded-2xl flex flex-col gap-4 min-h-[10rem] bg-[#ccc] shadow-none animate-pulse"></div>
              <div className="shadow-[gray] p-8 rounded-2xl flex flex-col gap-4 min-h-[10rem] bg-[#ccc] shadow-none animate-pulse"></div>
              <div className="shadow-[gray] p-8 rounded-2xl flex flex-col gap-4 min-h-[10rem] bg-[#ccc] shadow-none animate-pulse"></div>
              <div className="shadow-[gray] p-8 rounded-2xl flex flex-col gap-4 min-h-[10rem] bg-[#ccc] shadow-none animate-pulse"></div>
            </div>
          )}
          {display && (
            <div className="grid md:grid-cols-2 gap-[4rem] justify-center items-center w-[70vw] my-[2rem] m-auto">
              {customers && (
                <div className="text-center shadow-lg shadow-[gray] p-8 rounded-2xl flex flex-col gap-4 min-h-[10rem]">
                  <h2 className="text-khamco-secondary text-2xl font-bold">
                    Total Customers
                  </h2>
                  <h2 className="text-xl font-semibold text-green-500">
                    {customers.length}
                  </h2>
                  <Link
                    to="/admin/customers-data"
                    className="py-[0.5rem] px-[1rem] outline-none border-none bg-[blue] text-white text-lg rounded-md cursor-pointer hover:opacity-90"
                  >
                    Details
                  </Link>
                </div>
              )}
              <div className="text-center shadow-lg shadow-[gray] p-8 rounded-2xl flex flex-col gap-4 min-h-[10rem]">
                <h2 className="text-khamco-secondary text-2xl font-bold">
                  Revenue Generated
                </h2>
                <h2 className="text-xl font-semibold text-green-500">
                  KWD {new Intl.NumberFormat("en-US").format(totalRevenue)}
                </h2>
                <Link
                  to="/admin/revenue"
                  className="py-[0.5rem] px-[1rem] outline-none border-none bg-[blue] text-white text-lg rounded-md cursor-pointer hover:opacity-90"
                >
                  Details
                </Link>
              </div>
              <div className="text-center shadow-lg shadow-[gray] p-8 rounded-2xl flex flex-col gap-4 min-h-[10rem]">
                <h2 className="text-khamco-secondary text-2xl font-bold">
                  Total Fabric
                </h2>
                <h2 className="text-xl font-semibold text-green-500">
                  {availableFabric} meters
                </h2>
                <Link
                  to="/admin/fabrics"
                  className="py-[0.5rem] px-[1rem] outline-none border-none bg-[blue] text-white text-lg rounded-md cursor-pointer hover:opacity-90"
                >
                  Details
                </Link>
              </div>
              <div className="text-center shadow-lg shadow-[gray] p-8 rounded-2xl flex flex-col gap-4 min-h-[10rem]">
                <h2 className="text-khamco-secondary text-2xl font-bold">
                  Average Service Time
                </h2>
                <h2 className="text-xl font-semibold text-green-500">{`${
                  averageServiceTime.days && averageServiceTime.days.toFixed(2)
                } day(s), ${
                  averageServiceTime.hours &&
                  averageServiceTime.hours.toFixed(2)
                } hours`}</h2>

                <Link
                  to="/admin/service-times"
                  className="py-[0.5rem] px-[1rem] outline-none border-none bg-[blue] text-white text-lg rounded-md cursor-pointer hover:opacity-90"
                >
                  Details
                </Link>
              </div>
              <div className="text-center shadow-lg shadow-[gray] p-8 rounded-2xl flex flex-col gap-4 min-h-[10rem]">
                <h2 className="text-khamco-secondary text-2xl font-bold">
                  Invoice Data
                </h2>
                {invoiceData && (
                  <>
                    <h2 className="text-xl font-semibold text-green-500">{`${invoiceData[0].total} created`}</h2>
                    <h2 className="text-xl font-semibold text-red-500">
                      {`${invoiceData[0].sum} deleted`}
                    </h2>
                  </>
                )}
                <Link
                  to="/admin/invoice-data"
                  className="py-[0.5rem] px-[1rem] outline-none border-none bg-[blue] text-white text-lg rounded-md cursor-pointer hover:opacity-90"
                >
                  Details
                </Link>
              </div>
            </div>
          )}
        </section>
      </main>
      <Footer />
    </>
  );
};

export default AdminDashboard;

import { createStore, action } from "easy-peasy";

const store = createStore({
  userLanguage: localStorage.getItem("userLanguage") || "en",
  selectUserLanguage: action((state, payload) => {
    state.userLanguage = payload;
  }),
  writingDirection: action((state) => {
    if (state.userLanguage === "ar") {
      state.writingDirection = "rtl";
    } else {
      state.writingDirection = "ltr";
    }
  }),
  user: {},
  setUser: action((state, payload) => {
    state.user = payload
  })
});
export default store;

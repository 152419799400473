import React, { useEffect } from "react";
import Ribbon from "../components/Ribbon";
import Footer from "../components/Footer";

const NotFound = () => {
  useEffect(() => {
    document.title = "Page Not Found";
  }, []);

  return (
    <>
      <Ribbon />
      <main className="p-16">
        <h1 className="text-center text-[2rem] font-minimoBold">
          Page Not Found
        </h1>
        <div className="flex justify-center items-center my-[2rem] mx-auto flex-wrap w-full">
          <p>
            The page you have requested does not exist. This may also happen if
            you are not authorized to use that page.
          </p>
        </div>
      </main>
      <Footer />
    </>
  );
};

export default NotFound;

import React, { useState } from "react";
import { Link } from "react-router-dom";

const AdminPanel = () => {
  const isOpen = localStorage.getItem("isOpen") === "true";
  const [isChecked, setIsChecked] = useState(isOpen);

  return (
    <>
      <input
        type="checkbox"
        name="admin-nav"
        id="admin-nav"
        className="hidden"
        onChange={() => {
          setIsChecked(!isChecked);
          localStorage.setItem("isOpen", !isChecked);
        }}
      />
      <label
        htmlFor="admin-nav"
        className={`text-[2rem] cursor-pointer text-khamco-secondary transition-all z-[4]`}
      >
        {!isChecked && <i className="fas fa-bars"></i>}
        {isChecked && <i className="fas fa-times"></i>}
      </label>
      <ul
        className={`py-[2rem] px-0 list-none w-[80%] md:w-1/4 shadow-md shadow-[#666] rounded-[0.5rem] min-h-[100vh] text-center transition-all z-[3] bg-[white] absolute mt-[1rem] ${
          !isChecked ? "-translate-x-[125%]" : "-translate-x-0"
        }`}
      >
        <h2 className="no-underline mb-[2rem] text-2xl">Admin Panel</h2>
        <Link
          className="text-[blue] no-underline hover:underline cursor-pointer"
          to="/admin/dashboard"
          onClick={() => {
            setIsChecked(false);
            localStorage.setItem("isOpen", false);
          }}
        >
          <li
            className={`py-[1rem] px-0 border-b-[#555] border-b-[0.09rem] w-full transition-all hover:pl-[0.5rem] ${
              window.location.pathname === "/admin/dashboard"
                ? "bg-[tomato]"
                : ""
            }`}
          >
            Home <i className="fas fa-home"></i>
          </li>
        </Link>
        <Link
          className="text-[blue] no-underline hover:underline cursor-pointer"
          to="/admin/tailors"
          onClick={() => {
            setIsChecked(false);
            localStorage.setItem("isOpen", false);
          }}
        >
          <li
            className={`py-[1rem] px-0 border-b-[#555] border-b-[0.09rem] w-full transition-all hover:pl-[0.5rem] ${
              window.location.pathname === "/admin/tailors" ? "bg-[tomato]" : ""
            }`}
          >
            Manage Tailors <i className="fas fa-users"></i>
          </li>
        </Link>
        <Link
          className="text-[blue] no-underline hover:underline cursor-pointer"
          to="/admin/customers-data"
          onClick={() => {
            setIsChecked(false);
            localStorage.setItem("isOpen", false);
          }}
        >
          <li
            className={`py-[1rem] px-0 border-b-[#555] border-b-[0.09rem] w-full transition-all hover:pl-[0.5rem] ${
              window.location.pathname === "/admin/customers-data"
                ? "bg-[tomato]"
                : ""
            }`}
          >
            Customer Data <i className="fas fa-user-group"></i>
          </li>
        </Link>
        <Link
          className="text-[blue] no-underline hover:underline cursor-pointer"
          to="/admin/products"
          onClick={() => {
            setIsChecked(false);
            localStorage.setItem("isOpen", false);
          }}
        >
          <li
            className={`py-[1rem] px-0 border-b-[#555] border-b-[0.09rem] w-full transition-all hover:pl-[0.5rem] ${
              window.location.pathname === "/admin/products"
                ? "bg-[tomato]"
                : ""
            }`}
          >
            Inventory <i className="fas fa-box-archive"></i>
          </li>
        </Link>
        <Link
          className="text-[blue] no-underline hover:underline cursor-pointer"
          to="/admin/service-times"
          onClick={() => {
            setIsChecked(false);
            localStorage.setItem("isOpen", false);
          }}
        >
          <li
            className={`py-[1rem] px-0 border-b-[#555] border-b-[0.09rem] w-full transition-all hover:pl-[0.5rem] ${
              window.location.pathname === "/admin/service-times"
                ? "bg-[tomato]"
                : ""
            }`}
          >
            Service Data <i className="fas fa-shirt"></i>
          </li>
        </Link>
        <Link
          className="text-[blue] no-underline hover:underline cursor-pointer"
          to="/admin/invoice-data"
          onClick={() => {
            setIsChecked(false);
            localStorage.setItem("isOpen", false);
          }}
        >
          <li
            className={`py-[1rem] px-0 border-b-[#555] border-b-[0.09rem] w-full transition-all hover:pl-[0.5rem] ${
              window.location.pathname === "/admin/invoice-data"
                ? "bg-[tomato]"
                : ""
            }`}
          >
            Invoice Data <i className="fas fa-receipt"></i>
          </li>
        </Link>
        <Link
          className="text-[blue] no-underline hover:underline cursor-pointer"
          to="/orders"
          onClick={() => {
            setIsChecked(false);
            localStorage.setItem("isOpen", false);
          }}
        >
          <li className="py-[1rem] px-0 border-b-[#555] border-b-[0.09rem] w-full transition-all hover:pl-[0.5rem]">
            Orders <i className="fas fa-bookmark"></i>
          </li>
        </Link>
      </ul>
    </>
  );
};

export default AdminPanel;

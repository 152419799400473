import { Routes, Route, Navigate, useNavigate } from "react-router-dom";
import Login from "./pages/Login";
import NotFound from "./pages/404";
import { Suspense, useCallback, useEffect } from "react";
import { Slide, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useStoreActions } from "easy-peasy";
import { jwtDecode } from "jwt-decode";
import { appRoutes } from "./routes";
import ScrollToTop from "./components/ScrollToTop";
import ForgotPassword from "./pages/users/ForgotPassword";
import ResetPassword from "./pages/users/ResetPassword";

function App() {
  const authKey = localStorage.getItem("jwt");
  const setUser = useStoreActions((state) => state.setUser);
  const navigate = useNavigate();

  const isTokenExpired = useCallback((token) => {
    const payloadBase64 = token.split(".")[1];
    const decodedJson = atob(payloadBase64);
    const decoded = JSON.parse(decodedJson);
    const exp = decoded.exp;
    const now = Date.now().valueOf() / 1000;
    return now > exp;
  }, []);

  useEffect(() => {
    document.title = "Khamco Shop";

    const isLoggedIn = () => {
      const token = authKey;
      return token != null && !isTokenExpired(token);
    };

    if (!isLoggedIn()) {
      localStorage.clear();
    }

    const getUser = () => {
      if (authKey) {
        try {
          const decoded = jwtDecode(authKey);
          setUser({
            id: decoded.id,
            email: decoded.email,
            role: decoded.role,
            language: decoded.language,
          });
        } catch (error) {
          console.error(error);
        }
      }
    };
    getUser();
  }, [authKey, isTokenExpired, navigate, setUser]);

  return (
    <Suspense
      fallback={
        <div className="w-screen h-screen flex items-center justify-center">
          <i className="text-[5rem] text-khamco-ribbon fas fa-spinner animate-spin"></i>
        </div>
      }
    >
      <ScrollToTop />
      <Routes>
        <Route exact path="/users/login" element={<Login />} />
        {appRoutes.map((route) => (
          <Route
            key={route.path}
            exact
            path={route.path}
            element={
              authKey ? <route.component /> : <Navigate to="/users/login" />
            }
          />
        ))}
        {/* Misc Routes */}
        <Route
          exact
          path="/users/forgot-password"
          element={<ForgotPassword />}
        />
        <Route exact path="/users/reset-password/:token" element={<ResetPassword />} />
        <Route exact path="/unauthorized" element={<NotFound />} />
        <Route exact path="*" element={<NotFound />} />
      </Routes>
      <ToastContainer
        position="top-right"
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        transition={Slide}
        autoClose={5000}
      />
    </Suspense>
  );
}

export default App;

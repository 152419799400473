import { lazy } from "react";
import Home from "./pages/Home";
import AdminDashboard from "./pages/admin/AdminDashboard";
const UserProfile = lazy(() => import("./pages/users/UserProfile"));
const MyOrders = lazy(() => import("./pages/orders/MyOrders"));
const EditDependent = lazy(() =>
  import("./pages/customers/dependents/EditDependent")
);
const DependentsDetails = lazy(() =>
  import("./pages/customers/dependents/DependentsDetails")
);
const CreateMeasurement = lazy(() =>
  import("./pages/customers/measurements/CreateMeasurement")
);
const EditMeasurement = lazy(() =>
  import("./pages/customers/measurements/EditMeasurement")
);
const CustomerInvoices = lazy(() =>
  import("./pages/customers/CustomerInvoices")
);
const Measurements = lazy(() =>
  import("./pages/customers/measurements/Measurements")
);
const MeasurementDetails = lazy(() =>
  import("./pages/customers/measurements/MeasurementDetails")
);
const CreateCustomer = lazy(() => import("./pages/customers/CreateCustomer"));
const CustomerDetails = lazy(() => import("./pages/customers/CustomerDetails"));
const Customers = lazy(() => import("./pages/customers/Customers"));
const CreateDependent = lazy(() =>
  import("./pages/customers/dependents/CreateDependent")
);
const DependentsPrompt = lazy(() =>
  import("./pages/customers/dependents/DependentsPrompt")
);
const EditCustomer = lazy(() => import("./pages/customers/EditCustomer"));
const NewProduct = lazy(() => import("./pages/products/NewProduct"));
const Products = lazy(() => import("./pages/products/Products"));
const ProductView = lazy(() => import("./pages/products/ProductView"));
const EditProduct = lazy(() => import("./pages/products/EditProduct"));
const Services = lazy(() => import("./pages/services/Services"));
const NewService = lazy(() => import("./pages/services/NewService"));
const ServicesView = lazy(() => import("./pages/services/ServicesView"));
const EditService = lazy(() => import("./pages/services/EditService"));
const Invoices = lazy(() => import("./pages/invoices/Invoices"));
const ShowInvoice = lazy(() => import("./pages/invoices/ShowInvoice"));
const NewInvoice = lazy(() => import("./pages/invoices/NewInvoice"));
const Orders = lazy(() => import("./pages/orders/Orders"));
const NewOrder = lazy(() => import("./pages/orders/NewOrder"));
const OrderDetails = lazy(() => import("./pages/orders/OrderDetails"));
const CustomersData = lazy(() => import("./pages/admin/CustomersData"));
const AdminRevenue = lazy(() => import("./pages/admin/AdminRevenue"));
const AdminFabrics = lazy(() => import("./pages/admin/AdminFabrics"));
const AdminInvoiceData = lazy(() => import("./pages/admin/AdminInvoiceData"));
const AdminServiceTimes = lazy(() => import("./pages/admin/AdminServiceTimes"));
const AdminTailors = lazy(() => import("./pages/admin/AdminTailors"));
const AdminTailorDetails = lazy(() =>
  import("./pages/admin/AdminTailorDetails")
);
const AdminProducts = lazy(() =>
  import("./pages/admin/products/AdminProducts")
);
const AdminProductsView = lazy(() =>
  import("./pages/admin/products/AdminProductsView")
);
const RegisterUser = lazy(() => import("./pages/users/RegisterUser"));
const CreateMaster = lazy(() => import("./pages/users/CreateMaster"));
const EditUser = lazy(() => import("./pages/users/EditUser"));

export const appRoutes = [
  {
    path: "/",
    component: Home,
  },
  // Customer Routes
  {
    path: "/customers",
    component: Customers,
  },
  {
    path: "/customers/new-customer",
    component: CreateCustomer,
  },
  {
    path: "/customers/:id",
    component: CustomerDetails,
  },
  {
    path: "/customers/edit/:id",
    component: EditCustomer,
  },
  {
    path: "/customers/:id/invoices",
    component: CustomerInvoices,
  },
  // Dependents Routes
  {
    path: "/customers/dependents/dependents-prompt/:id",
    component: DependentsPrompt,
  },
  {
    path: "/customers/create-dependent/:id",
    component: CreateDependent,
  },
  {
    path: "/customers/:id/dependents/:dependentId",
    component: DependentsDetails,
  },
  {
    path: "/customers/edit/:id/dependents/:dependentId",
    component: EditDependent,
  },
  // Measurement Routes
  {
    path: "/customers/:id/measurements",
    component: Measurements,
  },
  {
    path: "/customers/:id/measurements/:measurementId",
    component: MeasurementDetails,
  },
  {
    path: "/customers/:id/create-measurement",
    component: CreateMeasurement,
  },
  {
    path: "/customers/edit/:id/measurements/:measurementId",
    component: EditMeasurement,
  },
  // Product Routes
  {
    path: "/products",
    component: Products,
  },
  {
    path: "/products/new",
    component: NewProduct,
  },
  {
    path: "/products/:id",
    component: ProductView,
  },
  {
    path: "/products/edit/:id",
    component: EditProduct,
  },
  // Services Routes
  {
    path: "/services",
    component: Services,
  },
  {
    path: "/services/new",
    component: NewService,
  },
  {
    path: "/services/:id",
    component: ServicesView,
  },
  {
    path: "/services/edit/:id",
    component: EditService,
  },
  //Invoices Routes
  {
    path: "/invoices",
    component: Invoices,
  },
  {
    path: "/invoices/:id",
    component: ShowInvoice,
  },
  {
    path: "/invoices/new/:id",
    component: NewInvoice,
  },
  // Orders Routes
  {
    path: "/orders",
    component: Orders,
  },
  {
    path: "/orders/new-order/:id",
    component: NewOrder,
  },
  {
    path: "/orders/:id",
    component: OrderDetails,
  },
  {
    path: "/my-orders/:id",
    component: MyOrders,
  },
  // Admin Routes
  {
    path: "/admin/dashboard",
    component: AdminDashboard,
  },
  {
    path: "/admin/customers-data",
    component: CustomersData,
  },
  {
    path: "/admin/revenue",
    component: AdminRevenue,
  },
  {
    path: "/admin/fabrics",
    component: AdminFabrics,
  },
  {
    path: "/admin/invoice-data",
    component: AdminInvoiceData,
  },
  {
    path: "/admin/service-times",
    component: AdminServiceTimes,
  },
  {
    path: "/admin/tailors",
    component: AdminTailors,
  },
  {
    path: "/admin/users/:id",
    component: AdminTailorDetails,
  },
  {
    path: "/admin/products",
    component: AdminProducts,
  },
  {
    path: "/admin/products/:id",
    component: AdminProductsView,
  },
  // Users Routes
  {
    path: "/users/register-user",
    component: RegisterUser,
  },
  {
    path: "/users/create-master",
    component: CreateMaster,
  },
  {
    path: "/users/edit-user/:id",
    component: EditUser,
  },
  {
    path: "/users/my-dashboard/:id",
    component: UserProfile,
  },
];

import { useCallback, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { useStoreState } from "easy-peasy";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const Home = () => {
  const userLanguage = useStoreState((state) => state.userLanguage);
  const [writingDirection, setWritingDirection] = useState("ltr");
  const authKey = localStorage.getItem("jwt");
  const navigate = useNavigate();
  const user = useStoreState((state) => state.user);
  const { t } = useTranslation();

  const isTokenExpired = useCallback((token) => {
    const payloadBase64 = token.split(".")[1];
    const decodedJson = atob(payloadBase64);
    const decoded = JSON.parse(decodedJson);
    const exp = decoded.exp;
    const now = Date.now().valueOf() / 1000;
    return now > exp;
  }, []);

  useEffect(() => {
    document.title = "Khamco Shop Home";

    const isLoggedIn = () => {
      const token = authKey;
      return token != null && !isTokenExpired(token);
    };

    if (!isLoggedIn()) {
      localStorage.clear();
      navigate("/users/login");
    }

    if (!user) {
      toast.warn("You are not authorized to access that resource.");
    }

    const determineWritingDirection = () => {
      if (userLanguage === "ar" || userLanguage === "ur") {
        setWritingDirection("rtl");
        console.log(writingDirection);
      }
    };

    determineWritingDirection();
  }, [isTokenExpired, authKey, navigate, userLanguage, writingDirection, user]);

  return (
    <>
      <Navbar />
      <main className="p-16 w-[90%] m-auto font-minimoMedium">
        <h1 className="text-center text-[2rem] font-minimoBold">
          {t("Main Menu")}
        </h1>
        <div className="flex justify-end gap-8 items-center mx-auto my-[3rem] w-full flex-wrap">
          <p>
            User: <span className="text-khamco-primary">{user.email}</span>
          </p>
        </div>
        <div
          className="flex justify-center items-center my-8 flex-wrap w-full"
          dir={writingDirection}
        >
          <div className="w-full grid grid-cols-1 md:grid-cols-3 gap-12 justify-center min-h-screen">
            <Link
              to="/customers"
              className="flex flex-col items-center justify-center gap-2 p-8 rounded-3xl bg-[#0000ffcc] text-white text-lg hover:bg-[#0000ff]"
            >
              <i className="fas fa-users"></i>
              <p>{t("Customers")}</p>
            </Link>
            <Link
              to="/products"
              className="flex flex-col items-center justify-center gap-2 p-8 rounded-3xl bg-[#0000ffcc] text-white text-lg hover:bg-[#0000ff]"
            >
              <i className="fas fa-box-archive"></i>
              <p>{t("Inventory")}</p>
            </Link>
            <Link
              to={`/users/my-dashboard/${user.id}`}
              className="flex flex-col items-center justify-center gap-2 p-8 rounded-3xl bg-[#0000ffcc] text-white text-lg hover:bg-[#0000ff]"
            >
              <i className="fas fa-dashboard"></i>
              <p>Tailor Dashboard</p>
            </Link>
            <Link
              to="/services"
              className="flex flex-col items-center justify-center gap-2 p-8 rounded-3xl bg-[#0000ffcc] text-white text-lg hover:bg-[#0000ff]"
            >
              <i className="fas fa-shirt"></i>
              <p>{t("Services")}</p>
            </Link>
            <Link
              to="/invoices"
              className="flex flex-col items-center justify-center gap-2 p-8 rounded-3xl bg-[#0000ffcc] text-white text-lg hover:bg-[#0000ff]"
            >
              <i className="fas fa-receipt"></i>
              <p>{t("Invoices")}</p>
            </Link>
            <Link
              to="/orders"
              className="flex flex-col items-center justify-center gap-2 p-8 rounded-3xl bg-[#0000ffcc] text-white text-lg hover:bg-[#0000ff]"
            >
              <i className="fas fa-bookmark"></i>
              <p>{t("Orders")}</p>
            </Link>
            <Link
              to="/admin/dashboard"
              className="flex flex-col items-center justify-center gap-2 p-8 rounded-3xl bg-[#0000ffcc] text-white text-lg hover:bg-[#0000ff]"
            >
              <i className="fas fa-ban"></i>
              <p>{t("Administration")}</p>
            </Link>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
};

export default Home;
